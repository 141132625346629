import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
  SpacePartial,
} from '../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const groupSnippet = require('raw-loader!../../../data/snippets/avatar-group.example');
const teamSnippet = require('raw-loader!../../../data/snippets/avatar-team.example');
const userSnippet = require('raw-loader!../../../data/snippets/avatar-user.example');
const orgSnippet = require('raw-loader!../../../data/snippets/avatar-org.example');

const ReactNativeTab = ({ avatarType }) => {
  return (
    <PlatformTab>
      {avatarType === 'group' && (
        <CodeSnippet
          disableCodeEditing
          code={groupSnippet}
          platform="react-native"
          gitHubLink="avatars/avatar-group"
        />
      )}
      {avatarType === 'team' && (
        <CodeSnippet
          disableCodeEditing
          code={teamSnippet}
          platform="react"
          gitHubLink="avatars/avatar-team"
        />
      )}
      {avatarType === 'user' && (
        <CodeSnippet
          disableCodeEditing
          code={userSnippet}
          platform="react"
          gitHubLink="avatars/avatar-user"
        />
      )}
      {avatarType === 'org' && (
        <CodeSnippet
          disableCodeEditing
          code={orgSnippet}
          platform="react"
          gitHubLink="avatars/avatar-org"
        />
      )}

      <Section title="Props">
        <PropList header="Visual">
          {avatarType === 'group' && (
            <PropListItem name="groupCount" types={['string']}>
              <Text>The number of people in the group.</Text>
            </PropListItem>
          )}

          {avatarType !== 'group' && (
            <PropListItem name="imageUrl" types={['string']}>
              <Text>The location of the photo to used with the avatar.</Text>
            </PropListItem>
          )}

          {avatarType === 'user' && (
            <PropListItem name="initials" types={['string']}>
              <Text>The user’s initials.</Text>
            </PropListItem>
          )}

          {avatarType === 'org' && (
            <PropListItem name="initials" types={['string']}>
              <Text>The org, school or brand’s initials.</Text>
            </PropListItem>
          )}

          <PropListItem name="primaryColor" types={['string']}>
            <Text>
              The hex or rgb value of the primary color to be used within the
              avatar.
            </Text>
          </PropListItem>

          <PropListItem name="secondaryColor" types={['string']}>
            <Text>
              The hex or rgb value of the secondary color to be used within the
              avatar.
            </Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the avatar:</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
              <li>
                <code>xlarge</code>
              </li>
              <li>
                <code>profile</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />

          {avatarType === 'team' && (
            <PropListItem name="sport" types={['string']}>
              <Text>The name of the team’s sport.</Text>
            </PropListItem>
          )}
        </PropList>

        <PropList header="Events">
          <PropListItem name="onPress" types={['() => void']}>
            <Text>Responds to the user tapping the avatar.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="avatar" />

          <TestIdPartial componentName="avatar" />
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            An accessbility check is automatically made when you pass in{' '}
            <code>primaryColor</code> and <code>secondaryColor</code>. If the
            check fails, the <code>secondaryColor</code> will be adjusted to a
            passing color.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Avatars"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
